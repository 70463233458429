import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container,
  Section,
  StyledImage,
  Flex,
  ImageWrapper,
} from "../../global"

const Author = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "knjiga" }, name: { eq: "avtor" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <StyledSection id="avtor">
      <GetStartedContainer>
        <Flex>
          <AuthorText>
            <AuthorTitle>Avtor</AuthorTitle>
            Boštjan Hari je magister teologije in član katoliškega zavoda
            Kerigma. Je voditelj seminarjev za notranje ozdravljenje, seminarjev
            na temo: Zdravje in Bog, duhovnih obnov, tečajev o molitvi;
            predavatelj na bibličnih, zakonskih, mladinskih, molitvenih
            srečanjih, skupinah in različnih kulturnih, duhovnih in
            evangelizacijskih dogodkih. Predava biblične in dogmatične vsebine,
            krščanski pogled na vzhodnjaške filozofije (joga, meditacija), new
            age, alternativno in ›energetsko‹ medicino. Znotraj zavoda Kerigma
            vodi in ustanavlja biblične tečaje Figulus.
          </AuthorText>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </GetStartedContainer>
    </StyledSection>
  )
}

export default Author

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const AuthorTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const AuthorText = styled.h5`
  padding: 1rem;
  text-align: center;
  font-size: 1.3rem;
`
