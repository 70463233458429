import React from "react"
import styled from "styled-components"

import {
  Section,
  SectionTitle,
  StyledContainer,
  SectionSubtitle,
} from "../../global"

const AboutBook = () => (
  <Section id="features">
    <StyledContainer>
      <SectionTitle>Vsebina</SectionTitle>
      <SectionSubtitle>
        Knjiga je bogat priročnik za vse iskalce duhovnega in čustvenega
        (duševnega) zdravja, notranjega miru, smisla in polnosti življenja. Je
        zelo ›življenjska‹ in konkretno odgovarja na mnoga vprašanja, povezana s
        človekovim zdravjem, duhovnostjo, smislom bivanja, odnosi, spolnostjo.
        Naj navedemo le nekatera:{" "}
      </SectionSubtitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>
            Kje vsak človek potrebuje ozdravljenje in kako priti do njega; kako
            dosegati in ohranjati duhovno in čustveno zdravje, ki sta ključna za
            celostno zdravje in osebnostno rast
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Zakaj prihaja do psihosomatskih obolenj in bolezni, ki jim tudi
            najsodobnejša medicina in psihiatrija nista kos, kako te preprečiti
            in priti do ozdravljenja{" "}
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Zakaj je duhovno zdravje, ne le za verujoče, ampak tudi ostale
            temeljno in najpomembnejše
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Kaj lahko v človeku ozdravi samo Bog </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Kaj je največji sovražnik in uničevalec človeka
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Kaj najbolj škoduje človekovemu zdravju?</FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Zakaj sta odpuščanje in zdravje neločljivo povezana?
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Kaj je razlika med jezo in besom</FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Kakšno je stališče znanosti in krščanstva do: alternativne,
            holistične in ›energetske‹ medicine – tehnik in metod zdravljenja z
            ›energijami‹ –, homeopatije, bioenergije, bioresonance,
            refleksoterapije, kiropraktike, akupunkture, ajurvede ...
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Razlika med alternativno in konvencionalno (uradno) medicino
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Razlogi za priljubljenost alternativne medicine in njene značilnosti
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Resnica o jogi, krščanski pogled na jogo: je filozofija joge skladna
            z Jezusovim naukom, obstaja joga kot telovadba, so jogijske vaje
            (asane) sprejemljive za kristjana, katere so pasti in nevarnosti
            joge
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Primerjava poti odrešenja (do sreče, izpolnjenosti, polnosti
            življenja) v jogi in krščanstvu
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Krščanski pogled na transcendentalno meditacijo, reiki, tai chi
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Razlika med krščansko in novodobsko (new age) duhovnostjo
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            So Božje zapovedi povezane s človekovim zdravjem, v čem so koristne
            in smiselne, zakaj nam je Bog dal zapovedi, obenem pa svobodno voljo
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Zakaj je Bog v Stari zavezi dopuščal toliko nasilja in ubijanja
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Kakšen naj bo odnos med zakoncema, odnos staršev do otrok in obratno
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Krščanski pogled na spolnost</FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Resnica o homoseksualnosti: razlogi zanjo, kako je ta ozdravljiva
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Resnica o kontracepciji: v čem je škodljiva in nevarna
          </FeatureTitle>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            kakšen je pomen besed: ljubezen, svoboda, ponižnost, pobožnost,
            zaveza
          </FeatureTitle>
        </FeatureItem>
      </FeaturesGrid>
      <br />
      <br />
      <h4>
        Na ta in podobna vprašanja skuša pričujoča knjiga dati konkretne
        odgovore in nasvete, kako se rešiti notranjih bremen in zaživeti
        polneje.
      </h4>
    </StyledContainer>
  </Section>
)

export default AboutBook

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  font-size: 20px;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 23px;
  margin-bottom: 10px;
  text-align: center;
  font-family: ${(props) => props.theme.font.normal};
  font-style: italic;
`
