import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container,
  StyledHeaderImage,
  Flex,
  ImageWrapper,
  StyledLink,
  ActionButton,
  HeaderTextGroup,
  Subtitle,
  HeaderWrapper,
} from "../../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "knjiga" }
        name: { eq: "knjiga-naslovnica" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Knjiga</Subtitle>
            <h1>
              Božja ljubezen
              <br />
              odpušča in ozdravlja
            </h1>
            <h2>
              Nahajamo se v kulturi, kjer izražamo kult telesu, lepoti in
              zdravju. Vsak človek si želi biti zdrav v duši in telesu. Marsikdo
              bi za zdravje naredil vse. Ponudb za doseganje in ohranjanje
              celostnega zdravja in notranjega miru ne manjka, in nihče se ju ne
              brani. Človek je duhovno, duševno in telesno bitje. Vsi trije
              »deli« so med seboj tesno povezani in neločljivi.
            </h2>
            <StyledLink to="/knjiga/">
              <ActionButton>Več o knjigi</ActionButton>
            </StyledLink>
            <StyledLink to="/mnenja/">
              <MnenjaButton>Mnenja</MnenjaButton>
            </StyledLink>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledHeaderImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

const MnenjaButton = styled(ActionButton)`
  background: ${(props) => props.theme.color.accent};
  margin-top: 1rem;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
    color: #fff;
  }
`

export default Header
